#view-accounts1-container {
    width: 100%;
    background-color: #fafafa;

    .breadcrumbs ul {
        display: flex;
        margin: 20px;
    }
    .header-box {
        width: 100%;
        background-color: #ffffff;
        box-sizing: border-box;
        border-bottom: 2px solid #dcdcdc;
        padding: 2rem;
        padding-bottom: 0;
    }
    .header-box table tr th {
        padding: 10px 15px;
        font-size: 14px;
        line-height: 24px;
        color: #a0a0a0;
        font-weight: 400;
        text-align: left;
        background-color: #ffffff;
    }
    .header-box table tr td {
        padding: 10px 15px;
        font-size: 18px;
        line-height: 24px;
        color: #202020;
        font-weight: 500;
        text-align: left;
    }
    .nav {
        display: flex;
    }
    .nav-list {
        display: flex;
        justify-content: center;
    }
    .nav-list li {
        list-style: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #a0a0a0;
    }
    .nav-list .tab-active {
        font-weight: 700;
        box-shadow: inset 0 -2px 0 #24c4cc;
        color: #202020;
    }
    .nav-list li a {
        display: block;
        font-size: 1rem;
        padding: 1rem;
    }

    .table-box {
        box-sizing: border-box;
        border: 1px solid #dcdcdc;
        border-radius: 8px;
        background: #ffffff;
        color: #202020;
        table {
            margin-bottom: 0;
            width: 100%;
        }
        table thead tr th {
            border-bottom: 1px solid #dcdcdc;
            font-weight: 700;
            font-size: 12px;

            text-align: left;
        }
        table tbody tr td {
            border-bottom: 1px solid #dcdcdc;
            font-weight: 500;
            font-size: 12px;
            text-align: left;
        }
        table thead tr:last-child {
            border-bottom: none;
        }
        table tbody tr:last-child {
            border-bottom: none;
        }
        .checkbox-input {
            text-align: center;
        }
    }
    .sub-account-table {
        background: #fafafa;
        border-radius: 8px;
        color: #727272;
    }
    .arrow {
        content: "\F238";
    }
    .filter-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .blue-button {
        background: #24c4cc;
        border-radius: 4px;
        color: #ffffff;
        padding: 0.5rem;
        border: #24c4cc;
    }
    .cancel-button{
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dcdcdc;
        color: #202020;
        padding: 0.5rem;
    }
    .receipt-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .sort-list {
        border: 0.5px solid #ccc;
        color: #4f4f4f;
        font-size: 14px;
        border-radius: 4px;
        background: #ffffff;
        top: 430px;
        right: 30px;
        overflow-y: auto;
        width: fit-content;
        position: absolute;
        box-shadow: 0 2px 10px rgba(0, 31, 69, 30%);
    }

    .sort-list li {
        padding: 10px;
        display: block;
        color: #4f4f4f;
        cursor: pointer;
    }
    .sort-list li:hover {
        background: #f2f2f2;
    }
    .list-unstyled {
        list-style: none;
        padding-left: 0;
    }
    .actions-flex{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .modal {
        display: block;
        position: fixed;
        z-index: 3;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.4);
    }
    .modal-content {
        background: #ffffff;
        border: 1px solid #dcdcdc;
        margin: auto;
        width: 40%;
        border-radius: 8px;
    }
    .title-container {
        padding-left: unset;
        font-weight: 700;
        font-size: 21px;
        color: #202020;
        padding: 0.8rem;
        border-bottom: 1px solid #dcdcdc;
    }
    .form-container {
        padding: 1.5rem;
    }
    .download-receipt-container {
        padding-right: unset;
    }
    .crete-doc-container{
        padding: 1rem;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    .popup-box {
        padding: 0.5rem;
        position: fixed;
        width: 40%;
        height: 5%;
        left: 575px;
        top: 0px;
        color: white;
        background: #00A758;
        border-radius: 0px 0px 4px 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
}
